
import {config} from '@/utils'

import homeTitlepc from '@/components/home/homeTitlepc.vue'
import headerBanner from '../home/banner.vue'
import Bottoms from '../home/bottoms.vue'


export default {
    name: 'work',
    components: {
        homeTitlepc,
        headerBanner,
        Bottoms
        
    },

    mounted () {
     
    },

    created(){

    },

    methods: {
        opus(){
            window.open(this.config.gongxinyi)
        }
      

    },

    data () {
        return {
            config,
            headerImg:require('./images/banner.png'),
            bottomImg:require('./images/store.png'),

           
            
        }
    }
}
